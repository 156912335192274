// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
	--ion-color-primary: #0e94e9;
	--ion-color-primary-rgb: 29,78,216;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #0e94e9;
	--ion-color-primary-tint: #0e94e9;

  /** secondary **/
  --ion-color-secondary: #202934;
  --ion-color-secondary-rgb: 32, 41, 52;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1c242e;
  --ion-color-secondary-tint: #363e48;

  /** tertiary **/
  --ion-color-tertiary: #7E90A9;
  --ion-color-tertiary-rgb: 126, 144, 169;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #6f7f95;
  --ion-color-tertiary-tint: #8b9bb2;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #F47068;
  --ion-color-danger-rgb: 244, 112, 104;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #d7635c;
  --ion-color-danger-tint: #f57e77;

  /** dark **/
  --ion-color-dark: #141A1F;
  --ion-color-dark-rgb: 20, 26, 31;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #12171b;
  //--ion-color-dark-tint: #2c3135;
  --ion-color-dark-tint: #1d2226

  /** medium **/
	--ion-color-medium: #a1a1aa;
	--ion-color-medium-rgb: 161,161,170;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #8e8e96;
	--ion-color-medium-tint: #aaaab3;

  /** light **/
	--ion-color-light: #fafbff;
	--ion-color-light-rgb: 250,251,255;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #dcdde0;
	--ion-color-light-tint: #fbfbff;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;
  --ion-color-off-white: #ffffff;
}

.ios body {
  --ion-background-color: linear-gradient(to bottom, #1d2226, #232b2e);
  --ion-background-color-rgb: 255, 255, 255;

  --ion-text-color: #202934;
  --ion-text-color-rgb: 32, 41, 52;

  // --ion-color-step-50: #000000;
  // --ion-color-step-100: #1a1a1a;
  // --ion-color-step-150: #262626;
  // --ion-color-step-200: #333333;
  // --ion-color-step-250: #404040;
  // --ion-color-step-300: #4d4d4d;
  // --ion-color-step-350: #595959;
  // --ion-color-step-400: #666666;
  // --ion-color-step-450: #737373;
  // --ion-color-step-500: #808080;
  // --ion-color-step-550: #8c8c8c;
  // --ion-color-step-600: #999999;
  // --ion-color-step-650: #a6a6a6;
  // --ion-color-step-700: #b3b3b3;
  // --ion-color-step-750: #bfbfbf;
  // --ion-color-step-800: #cccccc;
  // --ion-color-step-850: #ffffff;
  // --ion-color-step-900: #e6e6e6;
  // --ion-color-step-950: #f2f2f2;

  --ion-item-background: #ffffff;

  --ion-card-background: #ffffff;
}

.ios ion-modal {
  --ion-background-color: #ffffff;
  --ion-toolbar-background: transparent;
  --ion-toolbar-border-color: transparent;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
